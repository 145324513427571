import i18nService from "@/core/services/i18n.service.js";
import AIComponent from "@/view/components/AIComponent";
import { INITIAL_SNACKBAR_INFO } from "@/view/components/SnackBarInfoComponent.vue";

export const formModelMixins = {
  components: {
    AIComponent,
  },
  data() {
    return {
      loader: false,
      errorLang: null,

      snackbarInfo: INITIAL_SNACKBAR_INFO,

      showAIBlock: false,

      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        disableNativeSpellChecker: false,
        extraPlugins: "justify,font,copyformatting",
        extraAllowedContent: "iframe[*]",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      editorConfig1: {
        htmlEncodeOutput: false,
        entities: false,
        disableNativeSpellChecker: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      editorConfig2: {
        htmlEncodeOutput: false,
        entities: false,
        disableNativeSpellChecker: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      editorConfig3: {
        htmlEncodeOutput: false,
        entities: false,
        disableNativeSpellChecker: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      editorConfig4: {
        htmlEncodeOutput: false,
        entities: false,
        disableNativeSpellChecker: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      editorConfigIntro: {
        disableNativeSpellChecker: false,
        toolbar: [
          ["Source"],
          ["Styles", "Format", "Font", "FontSize"],
          ["Bold", "Italic"],
          ["Undo", "Redo"],
          ["About"],
        ],
      },
    };
  },
  computed: {
    computedCols() {
      if (this.showAIBlock) return 9;

      return 12;
    },
    modalWidth() {
      if (this.initialModalWidth && this.showAIBlock) return 2560;

      return this.initialModalWidth ?? null;
    },
  },
  methods: {
    handleCloseModalForm() {
      this.resetErrorMessages();
      this.$emit("closeModalForm");
    },

    setTranslatedAttributes() {
      if (this.formModel && this.formModel.translations) {
        if (!this.formModel.id) {
          this.languages.forEach((language) => {
            this.translatedAttributes.forEach((attribute) => {
              if (!this.formModel.translations[language.lang]) {
                // this.formModel.translations.push(language.lang);
                this.formModel.translations[language.lang] = {};
              }
              // this.formModel.translations[language.lang][attribute] = null;
              this.formModel.translations[language.lang][attribute] = "";
            });
            if (this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang]["attachments"] = [];
            }
          });
        } else {
          this.languages.forEach((language) => {
            this.translatedAttributes.forEach((attribute) => {
              if (!this.formModel.translations[language.lang]) {
                this.formModel.translations[language.lang] = {};
              }
              
              if (this.formModel.translations[language.lang] && !this.formModel.translations[language.lang][attribute]) {
                this.formModel.translations[language.lang][attribute] = "";
              }
            });
          });
        }
      }

      this.initTranslatedCustomFields();
    },

    deleteUnusedTranslations(model) {
      let translations = {};
      if (this.translatedAttributes.length > 0) {
        i18nService.languages.forEach((item) => {
          let langCode = item.lang;

          this.translatedAttributes.forEach((attribute) => {
            if (
              model.translations[langCode] &&
              model.translations[langCode][attribute] &&
              attribute != "custom_fields"
            ) {
              // console.log(
              //   "!!!! model.translations[langCode]",
              //   model.translations[langCode],
              //   langCode,
              //   attribute,
              //   model.translations[langCode][attribute]
              // );
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
        });
      }

      model.translations = translations;

      return model;
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, this.initialFormData());
      if (this.languages.length) {
        this.languages.forEach((language) => {
          if (typeof this.translatedAttributes != "undefined") {
            this.translatedAttributes.forEach((attribute) => {
              errorData["translations." + language.lang + "." + attribute] = "";
            });
          }
        });
      }
      /**/
      this.messages = errorData;
      this.errorLang = null;
    },

    // setError(prop, value) {
    //   this.messages[prop] = value;
    // },

    setError(prop, value) {
      if (prop.search("translations.") != -1) {
        let langCode = prop.replace("translations.", "").substring(0, 2);

        if (!this.errorLang) {
          this.errorLang = langCode;
          this.selectedLocale = i18nService.languages.find((item) => {
            return item.lang == langCode;
          });
        } else {
          if (langCode == this.selectedLocale.lang) {
            this.errorLang = langCode;
            this.selectedLocale = i18nService.languages.find((item) => {
              return item.lang == langCode;
            });
          }
        }
      }
      this.messages[prop] = value;
    },

    handleCloseDelete() {
      this.modalData.editedId = null;
      this.dialogDelete = false;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == this.permissionTo + action);
    },

    synergyAssistant() {
      return this.permissions.find(
        (x) => x.name == "system.synergyfox.assistant"
      );
    },

    showSnackBarInfo() {
      let snackbarInfo = this.snackbarInfo;

      snackbarInfo.active = true;

      this.snackbarInfo = snackbarInfo;
    },

    generateSlug(input) {
      // Az input stringben lévő ékezetes karaktereket cseréld ki megfelelő karakterekre
      const slug = input
        .toLowerCase()
        .replace(/[áä]/g, 'a')
        .replace(/[éë]/g, 'e')
        .replace(/[í]/g, 'i')
        .replace(/[óöő]/g, 'o')
        .replace(/[úüű]/g, 'u')
        .replace(/[^a-z0-9]+/g, '-') // Egyebeket kötőjelre cseréli
        .replace(/^-+|-+$/g, ''); // Szélso whitespace kötőjeleket eltávolítja
      return slug;
    },
    handleChangeName() {
      if (
        !this.formModel.translations[this.selectedLocale.lang].slug ||
        this.formModel.translations[this.selectedLocale.lang].slug.length == 0
      ) {
        this.$set(
          this.formModel.translations[this.selectedLocale.lang],
          "slug",
          this.generateSlug(this.formModel.translations[this.selectedLocale.lang].name)
        );
        let formModel = this.formModel;
        this.formModel = Object.assign({}, this.initialFormData());
        this.formModel = Object.assign({}, formModel);
      }
    }
  },
};
